import { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Container from 'react-bootstrap/Container'
import Header from '../Components/Header/Header'
import { backendAccessPost, backendAccessGet, backendMe, logout } from '../Utils/Authentication'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Loading from '../Components/UserHome/Loading'
import Avatar from '../Components/Avatar/Avatar'

export default function AvatarPage( props )
{
    const me = props.me 
    const setMe = props.setMe
    const accounts = props.accounts 
    const setAccounts = props.setAccounts 
    const displayName = props.displayName 
    const setDiplayName = props.setDiplayName 
    const avatarImage = props.avatarImage 
    const setAvatarImage = props.setAvatarImage

    const userState = props.userState 
    const setUserState = props.setUserState 
    const userConfig = props.userConfig 
    const setUserConfig = props.setUserConfig 
    const userPoints = props.userPoints 
    const setUserPoints = props.setUserPoints 
    const accountType = props.accountType 
    const setAccountType = props.setAccountType 

    const state = props.state 
    const setState = props.setState

    const navigate = useNavigate()

    const stateLock = useRef(false)
    const [futureState, setFutureState] = useState(null)
    const [ hasChange, setHasChange ] = useState(false)

    useEffect( () => {
        setState("4")
    },[])

    return (
        <div className="d-flex justify-content-center" style={{ width: '100%', height: '100vh' }}>
            { ( me && userState && userConfig ) &&
                <div style={{ height: '100%', width: '100%' }}>
                    <div style={{ width: '100%', height:'calc(100% - 100px)' }}>
                        <Container style={{ maxWidth: '1200px' }}>
                            <Header me={me} avatarImage={avatarImage} 
                                    state={state} setState={setState} 
                                    stateLock={stateLock} 
                                    setFutureState={setFutureState}
                                    displayName={displayName}
                                    userPoints={userPoints}
                                    setUserPoints={setUserPoints}
                                    accountType={accountType}
                                    setAccountType={setAccountType}
                                    hasChange={hasChange}
                                    setHasChange={setHasChange}
                                    navigate={navigate}
                            />
                        </Container> 
                        <Avatar setMe={setMe} 
                                setAvatarImage={setAvatarImage} 
                                userConfig={userConfig}
                                accountType={accountType}
                        />
                    </div>
                </div>
            }
        </div>
    )
}
