import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useEffect, useState, useRef } from 'react'

// Pages
import Landing from './Pages/Landing'
import Home from './Pages/Home'
import Login from './Pages/Login'
import ProfilePage from './Pages/Profile'
import WidgetBrowserPage from './Pages/WidgetBrowser';
import WidgetEditorPage from './Pages/WidgetEditor';
import AvatarPage from './Pages/Avatar'
import InternetAccountsPage from './Pages/InternetAccounts'
import DownloadsPage from './Pages/Downloads';

// Authentication
import PrivateRoute from './Components/PrivateRoute'
import AlreadyLoggedIn from './Components/AlreadyLoggedIn'

import CookieConsent from './Legal/CookieConsent';
import TermsOfUse from './Legal/TermsOfUse'

// Auth login 
import TwitchLogin from './Components/InternetAccounts/TwitchLogin';

import ResetData from './Components/Data/ResetData';
import LoadData from './Components/Data/LoadData';

function App() { 
    const [me, setMe] = useState(null)
    const [accounts, setAccounts] = useState(null)
    const [displayName, setDiplayName] = useState(null)
    const [avatarImage, setAvatarImage] = useState(null)

    const [userState, setUserState] = useState(null)
    const [userConfig, setUserConfig] = useState(null)
    const [userPoints, setUserPoints] = useState(null)
    const [accountType, setAccountType ] = useState('mild')

    const userStateJson = useRef("")
    const [state, setState] = useState("")

    return (
        <div className="App">
            <CookieConsent>
                <Router>
                    <Routes>
                        <Route path="/" element={
                            <AlreadyLoggedIn to="/profile">
                                <ResetData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}
                                >
                                    <Landing />
                                </ResetData>
                            </AlreadyLoggedIn>
                        }/> 
                        <Route path="/Login" element={
                            <AlreadyLoggedIn to="/profile">
                                <Login to="/profile" />
                            </AlreadyLoggedIn>
                        }/>
                        <Route path="/profile" element={
                            <PrivateRoute to="/login">
                                <LoadData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}> 
                                    <ProfilePage me={me} setMe={setMe}
                                        accounts={accounts} setAccounts={setAccounts}
                                        displayName={displayName} setDiplayName={setDiplayName}
                                        avatarImage={avatarImage} setAvatarImage={setAvatarImage} 
                                        userState={userState} setUserState={setUserState}
                                        userConfig={userConfig} setUserConfig={setUserConfig}
                                        userPoints={userPoints} setUserPoints={setUserPoints}
                                        accountType={accountType} setAccountType={setAccountType}
                                        state={state} setState={setState}
                                    />
                                </LoadData>
                            </PrivateRoute>
                        }/>
                        <Route path="/widget-browser" element={
                            <PrivateRoute to="/login">
                                <LoadData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}> 
                                    <WidgetBrowserPage me={me} setMe={setMe}
                                        accounts={accounts} setAccounts={setAccounts}
                                        displayName={displayName} setDiplayName={setDiplayName}
                                        avatarImage={avatarImage} setAvatarImage={setAvatarImage} 
                                        userState={userState} setUserState={setUserState}
                                        userConfig={userConfig} setUserConfig={setUserConfig}
                                        userPoints={userPoints} setUserPoints={setUserPoints}
                                        accountType={accountType} setAccountType={setAccountType}
                                        state={state} setState={setState}
                                    />
                                </LoadData>
                            </PrivateRoute>
                        }/>
                        <Route path="/widget-editor" element={
                            <PrivateRoute to="/login">
                                <LoadData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}> 
                                    <WidgetEditorPage me={me} setMe={setMe}
                                        accounts={accounts} setAccounts={setAccounts}
                                        displayName={displayName} setDiplayName={setDiplayName}
                                        avatarImage={avatarImage} setAvatarImage={setAvatarImage} 
                                        userState={userState} setUserState={setUserState}
                                        userConfig={userConfig} setUserConfig={setUserConfig}
                                        userPoints={userPoints} setUserPoints={setUserPoints}
                                        accountType={accountType} setAccountType={setAccountType}
                                        state={state} setState={setState}
                                    />
                                </LoadData>
                            </PrivateRoute>
                        }/>
                        <Route path="/avatar" element={
                            <PrivateRoute to="/login">
                                <LoadData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}> 
                                    <AvatarPage me={me} setMe={setMe}
                                        accounts={accounts} setAccounts={setAccounts}
                                        displayName={displayName} setDiplayName={setDiplayName}
                                        avatarImage={avatarImage} setAvatarImage={setAvatarImage} 
                                        userState={userState} setUserState={setUserState}
                                        userConfig={userConfig} setUserConfig={setUserConfig}
                                        userPoints={userPoints} setUserPoints={setUserPoints}
                                        accountType={accountType} setAccountType={setAccountType}
                                        state={state} setState={setState}
                                    />
                                </LoadData>
                            </PrivateRoute>
                        }/>
                        <Route path="/internet-accounts" element={
                            <PrivateRoute to="/login">
                                <LoadData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}> 
                                    <InternetAccountsPage me={me} setMe={setMe}
                                        accounts={accounts} setAccounts={setAccounts}
                                        displayName={displayName} setDiplayName={setDiplayName}
                                        avatarImage={avatarImage} setAvatarImage={setAvatarImage} 
                                        userState={userState} setUserState={setUserState}
                                        userConfig={userConfig} setUserConfig={setUserConfig}
                                        userPoints={userPoints} setUserPoints={setUserPoints}
                                        accountType={accountType} setAccountType={setAccountType}
                                        state={state} setState={setState}
                                    />
                                </LoadData>
                            </PrivateRoute>
                        }/>
                        <Route path="/downloads" element={
                            <PrivateRoute to="/login">
                                <LoadData me={me} setMe={setMe}
                                      accounts={accounts} setAccounts={setAccounts} 
                                      displayName={displayName} setDiplayName={setDiplayName}
                                      avatarImage={avatarImage} setAvatarImage={setAvatarImage}
                                      userState={userState} setUserState={setUserState}
                                      userStateJson={userStateJson}
                                      userConfig={userConfig} setUserConfig={setUserConfig}
                                      userPoints={userPoints} setUserPoints={setUserPoints}
                                      accountType={accountType} setAccountType={setAccountType}
                                      state={state} setState={setState}> 
                                    <DownloadsPage me={me} setMe={setMe}
                                        accounts={accounts} setAccounts={setAccounts}
                                        displayName={displayName} setDiplayName={setDiplayName}
                                        avatarImage={avatarImage} setAvatarImage={setAvatarImage} 
                                        userState={userState} setUserState={setUserState}
                                        userConfig={userConfig} setUserConfig={setUserConfig}
                                        userPoints={userPoints} setUserPoints={setUserPoints}
                                        accountType={accountType} setAccountType={setAccountType}
                                        state={state} setState={setState}
                                    />
                                </LoadData>
                            </PrivateRoute>
                        }/>
                        <Route path="/oauth2/twitch" element={<TwitchLogin />}/>
                    </Routes>
                </Router>
            </CookieConsent>
        </div>
    );
}

export default App;

