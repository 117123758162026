import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import EffectItem from './EffectItem'
import Increment from './Increment'
import ScalarBox from "../../SpiceUI/ScalarBox"

import Control from "./Misc"

export default function RotationEffect( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx

    const [ hasRotation, setHasRotation ] = useState(null)
    const [ rotationSpeed, setRotationSpeed ] = useState(null)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }


        setHasRotation( false )
        if( layersCfg.current[selected].effects.rotation != null )
        {
            setRotationSpeed(layersCfg.current[selected].effects.rotation.speed )
            setHasRotation( true )
        }
    }

    useEffect( () => {
        reload()
    }, [] )

    useEffect( () => {
        if( hasRotation == null )
        {
            return
        }

        if( selectedIdx < 0 )
        {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( !hasRotation && layersCfg.current[selected].effects.rotation  )
        {
            delete layersCfg.current[selected].effects.rotation 
            setLayers( [...layers] )
        }

        if( hasRotation && !layersCfg.current[selected].effects.rotation )
        {
            layersCfg.current[selected].effects.rotation = {speed:10} 
            setRotationSpeed(layersCfg.current[selected].effects.rotation.speed )

            setLayers( [...layers] )
        }

    },[ hasRotation ] )

    useEffect( () => {
        if( selectedIdx < 0 || rotationSpeed == null )
        {
            return
        }
    
        var rotation_data = { speed: parseFloat(rotationSpeed) 
                          }

        layersCfg.current[selected].effects.rotation = rotation_data

        setLayers( [...layers] )
    },[ rotationSpeed ])

    useEffect( () => {
        reload()
    },[selected])

    return (
        <EffectItem valid={hasRotation} setValid={setHasRotation} name="Rotation">
            <Control name="speed"
                     ctrl={
                        <ScalarBox value={parseInt(rotationSpeed)}
                           setValue={setRotationSpeed}
                           step={1}
                           lowerBound={0}
                           upperBound={100} 
                        />

                     }
            />
        </EffectItem>
    )
}

