


export function loginWithTwitch( state ) 
{
    var payload = {}

    payload.client_id = "rslxflt3v5q7dmdd4y01zv5qbp0o0g"
    payload.scope = "user:read:email user:read:follows"
    payload.redirect_uri = "https://spice.stream/oauth2/twitch/"
    payload.response_type = "code"
    payload.state = state

    var str = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');

    window.location.replace("https://id.twitch.tv/oauth2/authorize?" + str)
}


export function loginWithYoutube( state )
{
    console.log("Youtube Login")
}
