import Spinner from 'react-bootstrap/Spinner';

export default function Loading( props )
{
    return (
        <div className="d-flex justify-content-center align-items-center"
             style={{ height:'100%', width:'100%'}}>
            <Spinner animation="grow" /> <h3>Loading ...</h3>
        </div>
    )
}
