import { useState } from 'react'


export default function ActionBox( props )
{
    const title = props.title
    const onClick = props.onClick
    const showTitle = ( props.showTitle ) ? props.showTitle : false

    const [ isHover, setIsHover ] = useState( false )

    return (
        <div style={{ width:'100%', padding:'10px' }}
             onMouseEnter={e => setIsHover(true)}
             onMouseLeave={e => setIsHover(false)}
        >
            <div className="d-flex justify-content-center align-items-center fw-light" 
                 style={{ width:'100%', height:'30px'}}>
            { ( isHover || showTitle ) &&
                <> {title}
                </>
            }
            </div>
            <div style={{ width:'100%', padding:'10px', borderRadius: '10px',
                          backgroundColor: (isHover) ? '#F8F8F8': '',
                          cursor:(onClick) ? 'pointer' : ''}}
                 onClick={onClick}
            >
                {props.children}
            </div>
        </div>
    )
}


