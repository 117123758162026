import { useState, useEffect, useRef } from 'react'
import Container from 'react-bootstrap/Container'


import { LoginModule } from '../Components/Landing/Login'


export default function Login(props) {
    const destination = props.to
   
    return (
        <div className="d-flex justify-content-center align-items-center" 
             style={{ width: '100%', height: '100vh' }}>
            <LoginModule to={destination}/>
        </div>
    )
}

