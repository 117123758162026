import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'

import ScaledImg from '../ScaledImg'
import ActionBox from '../SpiceUI/ActionBox'

import { FaWindows } from "react-icons/fa";
import { FaTwitch } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaDiscord } from "react-icons/fa";


import Avatar from '@mui/material/Avatar';

export function Downloads( props )
{
    return (
        <div className="fw-light" style={{ width:'100%', height:'113px' }} >
            <div style={{ width:'100%', marginBottom:'10px' }}>
                <div>
                    Spice.Stream (v 1.003-beta)
                </div>
                <div>
                    <FaWindows style={{ color:'#0d6efd', cursor:'pointer'}} />
                </div>
            </div>
            <div style={{ width:'100%' }}>
                <div>
                    OBS Plugins (v 1.003-beta) 
                </div>
                <div>
                    <FaWindows style={{ color:'#0d6efd', cursor:'pointer'}} />
                </div>
            </div>
        </div>
    )
}

export function InternetAccounts( props )
{
    const accounts = props.accounts

    const [ twitchColor, setTwitchColor ] = useState("#D7D7D7")

    useEffect( () => {
        if( accounts.twitch )
        {
            setTwitchColor('#6441a5')
        }
    },[])

    return (
        <>
            <FaTwitch style={{ marginRight: 15, fontSize:'20px', color:twitchColor }} />
            <FaYoutube style={{ marginRight: 15, fontSize:'20px', color:'#D7D7D7'}} />
            <FaFacebook style={{ marginRight:15, fontSize:'20px', color:'#D7D7D7'}} />
            <FaTiktok style={{ marginRight:15, fontSize:'20px', color:'#D7D7D7'}} />
            <FaXTwitter style={{ fontSize:'20px', color:'#D7D7D7'}} />
        </>
    )
}

export function Discord( props )
{
    return (
        <>
            <FaDiscord style={{ fontSize:'30px', color:'#5865F2'  }} />

        </>
    )
}

export function RandomMessage( props )
{
    const avatarImage = props.avatarImage
    const setState = props.setState
    const [ selectedIdx, setSelectedIdx ] = useState(0)

    useEffect(() => {
        let idx = Math.floor( Math.random() * 4 ) + 1
        setSelectedIdx( idx )
    },[])

    if( selectedIdx == 1 )
    {
        return (
            <div>
                You can upgrade your account to access premium features in 
                the top left menu. During the early acccess to can earn SPCs to upgrade your account for free.
            </div>
        )
    }

    if( selectedIdx == 2 )
    {
        return (
            <div>
                You can browse and choose existing widgets in 
                the <b style={{ cursor:'pointer' }} onClick={e => setState("1")}>Widget Browser</b>.
            </div>
        )
    }
    
    if( selectedIdx == 3 )
    {
        return (
            <div>
            You can easily create new widgets using 
            the <b style={{ cursor:'pointer' }} onClick={e => setState("2")}>Widget Builder</b>.
            </div>
        )
    }

    if( selectedIdx == 4 )
    {
        return (
            <div>
            You can create a custom avatar in <b style={{ cursor:'pointer' }} onClick={e => setState("4") }>Avatar & Logo</b>.
            Avatars of both the streamer and the viewers can dynamically show up in the widgets that support avatars.
            </div>
        )
    }

    /*if( selectedIdx == 5 )
    {
        return (
            <div>Message 4</div>
        )
    }*/

    return (
        <div></div>
    )
}


export function MiddleComponents(props) {
    const me = props.me 
    const avatarImage = props.avatarImage 
    const setState = props.setState


    return (
        <div className="fw-light" style={{ width: '500px', marginBottom:'5px', fontSize:'14px' }}>
            <div style={{ marginBottom:'10px'}}>
                Welcome to Spice.Stream's early access!!
            </div>
            <RandomMessage avatarImage={avatarImage} setState={setState} />
        {/*<div>
            We are glad that you are here to test our interactive widget builder. Both as a streamer and a viewer,
            you can get access to permimum features during our beta test by earning SPCs. You can also support us by
            subscribing to our service.
            </div>*/}
        </div>
    )
}

export default function Profile( props )
{
    const navigate = useNavigate()

    const me = props.me 
    const avatarImage = props.avatarImage
    const setState = props.setState
    const accounts = props.accounts

    useEffect( () => {
        setState("")
    },[])

    const openDownloads = () => {
        navigate('/downloads')
    }

    const openInternetAccounts = () => {
        navigate('/internet-accounts')
    }

    const joinDiscord = () => {
        window.open("https://discord.gg/6SuYakW4", "_blank")
    }


    return (
        <Container style={{height:'100%', maxWidth:'1200px'}}>
            <div className="d-flex justify-content-center align-items-end" 
                 style={{ height:'300px', width:'100%' }}>
                <div>
                <MiddleComponents me={me} 
                                  avatarImage={avatarImage}
                                  setState={setState}
                />
                {/*
                 <ActionBox title="Join our discord"
                                   onClick={joinDiscord}
                                   >
                            <Discord />
                        </ActionBox>
                */}
                </div>
            </div>
            <div style={{ height:'calc(100% - 300px)', width:'100%' }}>
                <div className="d-flex align-items-end" style={{ height:'100%', width:'300px', float:'left'}}>
                    <div style={{width:'100%', marginBottom:'50px'}}>
                        <ActionBox title="Join our discord"
                                   onClick={joinDiscord}
                                   >
                            <Discord />
                        </ActionBox>

                        {/*<ActionBox title="Downloads" 
                                   onClick={openDownloads}>
                            <Downloads />
                        </ActionBox>*/}
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-end" 
                     style={{ height:'100%', width:'calc(100% - 600px)', float:'left'}}
                >
                    <ScaledImg imageSrc={ (avatarImage) ? "data:image/png;base64, " + avatarImage : "images/avatar_profile.png" }/>
                </div>
                <div className="d-flex align-items-end" style={{ height:'100%', width:'300px', float:'right'}}>
                    <div style={{width:'100%', marginBottom:'50px'}}>
                        <ActionBox title="Internet Accounts" 
                                   onClick={openInternetAccounts}>
                            <InternetAccounts accounts={accounts} />
                        </ActionBox>
                    </div>
                </div>

            </div>
        </Container>
    )
}
