
import { useEffect } from 'react'

export default function ResetData( props )
{
    const me = props.me 
    const setMe = props.setMe
    const accounts = props.accounts 
    const setAccounts = props.setAccounts 
    const displayName = props.displayName 
    const setDiplayName = props.setDiplayName 
    const avatarImage = props.avatarImage 
    const setAvatarImage = props.setAvatarImage

    const userState = props.userState 
    const setUserState = props.setUserState 
    const userStateJson = props.userStateJson

    const userConfig = props.userConfig 
    const setUserConfig = props.setUserConfig 
    const userPoints = props.userPoints 
    const setUserPoints = props.setUserPoints 
    const accountType = props.accountType 
    const setAccountType = props.setAccountType 

    const state = props.state 
    const setState = props.setState

    useEffect( () => {
        setMe(null)
        setAccounts(null)
        setDiplayName(null)
        setAvatarImage(null)
        setUserState(null)
        setUserConfig(null)
        setUserPoints(null)
        setAccountType("mild")
        setState("")
    },[])

    return (
        <>{ props.children }</>
    )

}
