import { useState, useEffect } from "react"
import Form from 'react-bootstrap/Form'
import EffectItem from './EffectItem'
import Increment from './Increment'
import ScalarBox from "../../SpiceUI/ScalarBox"
import Button from '@mui/material/Button'

import RegionSelectionModal from './RegionSelectModal'
import { LiaPepperHotSolid } from "react-icons/lia";

import Control from "./Misc"

export default function AvatarEffect( props )
{
    const layers = props.layers 
    const setLayers = props.setLayers 
    const layersCfg = props.layersCfg
    const selected = props.selected 
    const selectedIdx = props.selectedIdx
    const modalOpen = props.modalOpen
    const canPlace = props.canPlace
    const disabled = props.disabled

    const [ hasAvatar, setHasAvatar ] = useState(null)
    const [ mode, setMode ] = useState(null)
    const [ rank, setRank ] = useState(null)
    const [ hashTag, setHashTag ] = useState(null)
    const [ region, setRegion ] = useState(null)

    const [ showModal, setShowModal ] = useState(false)

    const reload = () => {
        if( selectedIdx < 0 )
        {
            return;
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( layersCfg.current[selected].effects.avatar != null )
        {
            setMode(layersCfg.current[selected].effects.avatar.mode)
            setRank(layersCfg.current[selected].effects.avatar.rank)
            setHashTag(layersCfg.current[selected].effects.avatar.hash_tag)
            setRegion(layersCfg.current[selected].effects.avatar.region)
            setHasAvatar(true)
        }
        else
        {
            setHasAvatar(false)
        }
    }

    useEffect( () => {
        reload()
    }, [] )

    useEffect( () => {
        if( hasAvatar == null )
        {
            return
        }

        if( selectedIdx < 0 )
        {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }

        if( !hasAvatar && layersCfg.current[selected].effects.avatar  )
        {
            delete layersCfg.current[selected].effects.avatar 
            setLayers( [...layers] )
        }

        if( hasAvatar && !layersCfg.current[selected].effects.avatar )
        {
            layersCfg.current[selected].effects.avatar = {mode:'streamer', rank:0, hashTag:'', region:null} 
            setMode('streamer')
            setRank(0)
            setHashTag('')
            setRegion(null)

            setLayers( [...layers] )
        }

    },[ hasAvatar ] )

    useEffect( () => {
        if( !hasAvatar )
        {
            return
        }

        if (selectedIdx < 0 || mode == null || hashTag == null ) {
            return
        }

        if( !layersCfg.current[selected] )
        {
            return
        }


        var avatar_data = {
            mode: mode, 
            rank: rank,
            hash_tag: hashTag,
            region: region
        }

        layersCfg.current[selected].effects.avatar = avatar_data

        setLayers([...layers])

    },[ mode, rank, hashTag, region ])

    useEffect( () => {
        reload()
    },[selected])

    useEffect( () => {
        modalOpen.current = showModal
    },[showModal])


    return (
        <EffectItem valid={hasAvatar} setValid={setHasAvatar} 
                    name={<>Pin an Avatar</>}
                    disabled={disabled}
        >
            <Control name="Mode"
                     ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={mode} 
                             onChange={ e => setMode( e.target.value )}
                        >
                            <option value="streamer">Streamer</option>
                            <option value="chatter_1">Big chatter (1 min)</option>
                            <option value="chatter_5">Big chatter (5 min)</option>
                            <option value="hash_tag">Hash tag</option>
                            <option value="last_chat">Last chat</option>
                        </Form.Select>

                     }
            />
            <Control name="Rank"
                     ctrl={
                        <Form.Select style={{ width:'100%'}} size="sm" 
                             value={rank} 
                             onChange={ e => setRank( parseInt(e.target.value) )}
                        >
                            <option value={0}>1</option>
                            <option value={1}>2</option>
                            <option value={2}>3</option>
                            <option value={3}>4</option>
                            <option value={4}>5</option>
                            <option value={5}>6</option>
                            <option value={6}>7</option>
                            <option value={7}>8</option>
                            <option value={8}>9</option>
                            <option value={9}>10</option>
                        </Form.Select>
                     }
            />
            { mode == 'hash_tag' &&
            <Control name="#"
                     ctrl={
                        <Form.Control value={hashTag} 
                                      onChange={e => setHashTag(e.target.value)}
                                      style={{ width:'100%'}} 
                                      size="sm" 
                                      placeHolder="Hash Tag"
                        />
                     }
            />
            }

            { canPlace &&
            <div className="d-flex align-items-center justify-content-center m-1" >
                <div className=" d-flex align-items-center justify-content-center m-1" 
                     style={{ width: '100%', backgroundColor: '#FFF', 
                              height: '31px',fontSize:'12px' }} >
                    <Button size="sm" 
                            onClick={ e => setShowModal(true)} 
                            style={{ width:'100%', textTransform:'none' }}
                            variant={( region == null ) ? "contained" : ""}
                            size="small"
                    > 
                        Pin avatar
                    </Button>
                </div>
            </div>
            }

            { layers[selectedIdx] &&
                <RegionSelectionModal showModal={showModal} 
                                      setShowModal={setShowModal} 
                                      label="Avatar"
                                      image={layers[selectedIdx].patch} 
                                      region={region}
                                      setRegion={setRegion}
                />
            }

        </EffectItem>
    )
}

